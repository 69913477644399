var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-sim-cards" },
    [
      _c("div", { staticClass: "toolbar justify-content-between" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Admin" },
            on: { click: _vm.clickBack }
          })
        ]),
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.refresh,
            attrs: { title: "Refresh" },
            on: { click: _vm.refreshList }
          }),
          _c(
            "div",
            {
              staticClass: "icon-composite",
              on: {
                click: function($event) {
                  return _vm.$bvModal.show("modal-force-sim-sync")
                }
              }
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "icon-composite-main icon-composite-highlight",
                class: _vm.$config.icons.general.refresh,
                attrs: { title: "Force SIM Provider Sync" }
              }),
              _c("i", {
                staticClass: "icon-composite-child icon-composite-highlight",
                class: _vm.$config.icons.device.sim
              })
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "list-containter" },
        [
          _c(
            "b-card",
            [
              _c("b-card-header", [_c("h2", [_vm._v("SIM Card Admin")])]),
              _c(
                "b-card-body",
                [_c("admin-sim-card-list", { ref: "simList" })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-force-sim-sync",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Force SIM Provider Sync"
          }
        },
        [
          _c("p", [
            _vm._v(
              "This forces the system to perform a SIM Card sync with the selected provider. If the provider API is slow or there are a lot of SIM cards to sync this may time out "
            ),
            _c("strong", [
              _vm._v("but the operation will continue on the server. ")
            ]),
            _vm._v(
              " If you see a timeout message it does not necessarily mean the operation has failed."
            )
          ]),
          _c("b-form-select", {
            attrs: { options: _vm.simProviderOptions },
            model: {
              value: _vm.selectedProvider,
              callback: function($$v) {
                _vm.selectedProvider = $$v
              },
              expression: "selectedProvider"
            }
          }),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("modal-force-sim-sync")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.forceSync()
                    }
                  }
                },
                [_vm._v("Force Sync")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }