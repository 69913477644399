<template>
  <div class="admin-sim-cards">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
        <div class="icon-composite" @click="$bvModal.show('modal-force-sim-sync')">
          <i
            class="icon-composite-main icon-composite-highlight"
            :class="$config.icons.general.refresh"
            v-b-tooltip.hover
            title="Force SIM Provider Sync"
          ></i>
          <i
            class="icon-composite-child icon-composite-highlight"
            :class="$config.icons.device.sim"
          ></i>
        </div>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>SIM Card Admin</h2></b-card-header>
        <b-card-body>
          <admin-sim-card-list ref="simList"></admin-sim-card-list>
        </b-card-body>
      </b-card>
    </div>
<!--    modal-force-sim-sync-->
    <b-modal
      id="modal-force-sim-sync"
      centered
      class="modal-content"
      size="m"
      hide-footer
      title="Force SIM Provider Sync"
    >
      <p>This forces the system to perform a SIM Card sync with the selected provider. If the provider API is slow or
      there are a lot of SIM cards to sync this may time out <strong>but the operation will continue on the server.
        </strong> If you see a timeout message it does not necessarily mean the operation has failed.</p>
      <b-form-select :options="simProviderOptions" v-model="selectedProvider"></b-form-select>
      <div class="footer mt-2">
        <b-button class="button" @click="$bvModal.hide('modal-force-sim-sync')"
        >Cancel</b-button
        >
        <b-button class="button" @click="forceSync()">Force Sync</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import AdminSimCardList from '@/components/admin/AdminSIMCardList'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import {adminForceSIMSync} from '@/components/helpers/DataProvider'

export default {
  name: 'admin-shop-orders',
  components: {
    AdminSimCardList
  },
  data: function () {
    return {
      simProviderOptions: [
      ],
      selectedProvider: null
    }
  },
  created: async function () {
    let resp = await DataProvider.getConfig('admin/sim_providers')
    if (resp.success) {
      this.simProviderOptions = resp.data.sim_providers
    } else {
      ErrorHelper.displayDataErrorToast(resp)
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.simList.refreshTable()
    },
    forceSync: async function () {
      let resp = await DataProvider.adminForceSIMSync(this.selectedProvider)
      if (resp.success) {
        AlertHelper.successToast('SIM Sync Complete!', 'Sync Completed')
        this.$refs.simList.refreshTable()
        this.$bvModal.hide('modal-force-sim-sync')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-sim-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }


  .icon-composite {
    display: contents;
  }

  .icon-composite-main {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    background-color: transparent;
    color: $theme-color-primary-5;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite-child {
    position: relative;
    font-size: 1.2rem;
    left: -2.1rem;
    top: -0.6rem;
    background-color: transparent;
    color: $theme-color-primary-2;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite:focus,
  .icon-composite:hover .icon-composite-highlight {
    color: $theme-color-primary-1 !important;
    //border-color: $theme-color-primary-3;
    //background: $theme-color-secondary-2;
    text-shadow: 0px 0px 20px $theme-color-secondary-2,
    0px 0px 10px $theme-color-secondary-2;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
    flex-wrap: wrap;
  }

</style>
